<template>
    <svg class="" fill="none" :height="height" :width="width" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="m.063 0 9.9 13.237L0 24h2.242l8.723-9.423L18.012 24h7.63L15.185 10.018 24.458 0h-2.242l-8.033 8.678L7.693 0H.063ZM3.36 1.652h3.505l15.48 20.696h-3.506L3.359 1.652Z" :fill="fill" />
    </svg>
</template>
<script>
export default {
    props: {
        fill: {
            type: String,
            default: '#253139',
        },
        height: {
            type: String,
            default: "20px",
        },
        width: {
            type: String,
            default: "20px",
        },
    }
}
</script>
